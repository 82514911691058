###
Inject a helper to generate random and explicit background color clases
###

# Deps
import { cssClass } from '~/utils'

# All colors
colors = [
	'red-light'
	'red-lighter'
	'red-lightest'
	'yellow'
	'yellow-light'
	'yellow-lighter'
	'yellow-lightest'
	'blue'
	'blue-light'
	'blue-lighter'
	'blue-lightest'
	'green'
	'green-light'
	'green-lighter'
	'green-lightest'
	'pink'
	'pink-light'
	'pink-lighter'
	'pink-lightest'
]

# Pick a random color
randomColor = -> colors[Math.floor(Math.random() * colors.length)]

# Inject helper function
export default ({}, inject) -> inject 'backgroundColor', (color) ->
	cssClass color || randomColor(), 'background'
