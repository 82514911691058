###
Stores the current route at a time that is synced with the loading state so
things can listen to this and not trigger early.
###

export state = ->
	path: null
	transitioning: false

export mutations =

	# Set elements of the route
	set: (state, { path }) -> state.path = path

	# Set whether transitioning
	transitioning: (state, bool) -> state.transitioning = bool
