###
A Vue Mixin that calls onResize when the window is resized
###

# Definitions.styl settings
maxScaleWidth = 1440
minScaleWidth = 375

# The mixin
export default

	# Trigger callback on mount.  This acts like the watch had an "immediate:true"
	# but shouldn't fire before a component is ready to deal with it
	mounted: -> @onResize()


	computed:

		# Convenience accessors
		viewportW: -> @$store.state.viewport.width
		viewportH: -> @$store.state.viewport.height

		# The percent of the max-width of the viewport, like what is used by the
		# fluid() function in Stylus
		viewportPercent: ->
			perc = (@viewportW - minScaleWidth) / (maxScaleWidth - minScaleWidth)
			Math.max 0, Math.min 1, perc

	# Listen to width changes
	watch: viewportW: -> @onResize()

	# No-op that should be added to component this is mixed into
	methods: onResize: ->
