###
Inject Marked so it can be used in components
###
import Vue from 'vue'
import marked from 'marked'
renderer = new marked.Renderer()

# Make breaks from newlines, like shown in Contentful preview
marked.setOptions
	gfm: true
	breaks: true
	smartypants: true

# Customize paragraph
baseParagraph = renderer.paragraph
renderer.paragraph = (text) ->
	text = wrapLeadingQuote text
	return baseParagraph.call @, text

# Customize blockquote
baseBlockquote = renderer.blockquote
renderer.blockquote = (text) ->
	text = wrapLeadingQuote text
	return baseBlockquote.call @, text

# Surround leading quotes in a span so they can be offset
wrapLeadingQuote = (text) ->
	text.replace /^(“|‘|"|')/, '<span class="leading-quote">$1</span>'

# Export marked helper for use elsewhere
markedHelper = (str, inline) ->
	return '' unless str
	if inline
	then marked.inlineLexer str, []
	else marked str, renderer: renderer
export { markedHelper as marked }

# Inject marked helper
export default ({ }, inject) -> inject 'marked', markedHelper

# Also make filters
# https://github.com/vuejs/vue/issues/4352#issuecomment-283324408
Vue.filter 'wrapLeadingQuote', wrapLeadingQuote
