###
Make a JobPosting from a Tower
###
import organization from './organization'
import { marked } from '~/plugins/marked'
export default (tower) ->
	'@context': 'http://schema.org'
	'@type': 'JobPosting'
	datePosted: tower.createdAt
	description: getDescriptionHtml tower
	hiringOrganization: (bukwildOrg = organization 'bukwild')
	jobLocation: bukwildOrg.location
	title: getTitle tower
	employmentType: 'FULL_TIME' # I think this will be standard

# Get the title of the position from the marquee
getTitle = (tower) -> filterBlocks(tower, 'blockMarquee')[0]?.headline

# Merge the copy of all copy blocks
getDescriptionHtml = (tower) ->
	filterBlocks(tower, 'blockCopy').reduce (html, block) ->
		html + marked block.copy
	, ''

# Find blocks of a contentType
filterBlocks = (tower, contentType) ->
	(tower.blocks || []).filter (block) ->
		block.sys.contentType.sys.id == contentType
