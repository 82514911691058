
















import onResize from '~/utils/on-resize-mixin'
export default

	mixins: [ onResize ]

	props:
		parallax:
			type: Number
			default: -0.15

	data: -> height: null

	computed:

		# Calculate the negative margins
		margins: -> @parallax * @height if @height

		# Apply styles
		styles: ->
			marginTop: "#{@margins}px"
			marginBottom: "#{@margins}px"

	methods:

		# Measure the height of the slotted content
		onResize: ->
			return unless @parallax # Don't run if we're not applying parallax
			slot = @$refs.scrollResponder.$el.children.item(0)
			@height = slot.getBoundingClientRect().height

