###
Store Viewport values in VueX
###
import win from 'window-event-mediator'
export default ({ store }) ->

	# Update vuex
	update = -> store.commit 'viewport/setDimensions',
		width: window.innerWidth
		height: window.innerHeight

	# Listen and do an initial fire
	win.on 'resize', update, throttle: 100
	update()
