###
Currently just used for the Bukwild organization
###
export default ->
	'@context': 'http://schema.org'
	'@type': 'Organization'
	name: 'Bukwild'
	url: 'https://www.bukwild.com'
	logo: 'https://www.bukwild.com/logo.png'
	sameAs: [
		'https://twitter.com/BKWLD'
		'https://www.instagram.com/bkwld'
		'https://github.com/BKWLD'
		'https://gitlab.com/bukwild'
		'https://www.facebook.com/BKWLD'
		'https://www.linkedin.com/company/bkwld'
	]
	contactPoint: [
		'@type': 'ContactPoint'
		telephone: '+1-916-922-9200'
		contactType: 'sales'
	]
	location:
		'@type': 'Place'
		address:
			'@type': 'PostalAddress'
			streetAddress: '2421 17th Street #102'
			addressLocality: 'Sacramento'
			addressRegion: 'CA'
			postalCode: '95818'
