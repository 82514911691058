
















export default

	props: storeKey: String

	data: ->
		labelWidth: 0
		delayPassed: false

	# Show a bit after the page loads.  Adding some randomness so multiple badges
	# don't show up at exactly the same time
	mounted: ->
		@$wait 2000 + Math.random() * 500, =>
			@delayPassed = true
			@$nextTick => @labelWidth = @$refs.labelContents?.offsetWidth

	computed:

		# Store accessors
		store: -> @$store.state.updates[@storeKey] || {}

		# Pass the label width to CSS (needed a little extra width)
		cssVars: -> '--label-width': "#{@labelWidth + 2}px"

		# Show the badge if there is a lastUpdated date and it's newer than the last
		# time the user visited that section
		show: -> @store.updatedAt > @store.seenAt

