###
Firing GTM pageview events on mounted event, based on
https://github.com/nuxt-community/analytics-module/issues/8#issuecomment-423095503
###
import Vue from 'vue'
Vue.mixin mounted: ->
	return unless @$metaInfo?.title
	window?.dataLayer?.push
		event: 'Page Mounted'
		pageTitle: @$metaInfo.title
		pageFullPath: @$route.fullPath
