




















export default

	props:

		alignRight: Boolean

	computed:

		# If the slot has text, break each word up with a newline
		label: ->
			return unless text = @$slots?.default?[0]?.text
			text.replace " ", '<br>'

