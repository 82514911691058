import Vue from 'vue'
import 'vue-visual/index.css'
import visual from 'vue-visual'
import { img } from 'bukwild-contentful-utils'

# Test for webp
supportsWebp = false
if window?.Modernizr
	Modernizr.on 'webp', (bool) -> supportsWebp = bool

# Add the loader
import visualLoader from '~/components/global/visual-loader'
Vue.component 'visual-loader', visualLoader

# Set defaults
Vue.component('visual', visual).options.setDefaults

	# Default to cover
	background: 'cover'

	# Loading defaults
	load: 'visible'
	loadPoster: true
	transition: 'vv-fade'

	# Video defaults
	muted: true
	loop: true
	autoplay: 'visible'

	# Add the visual reveal loader
	loader: 'visual-loader'
	transitionLoader: ''

	# Handle Contentful media objects natively and generate a size equal to the
	# Visual.  Also creates posters automatically
	posterFromImage: true
	assetMutator: (asset, src, vm) ->
		posterSize = 8

		# Don't do any extra to video or string srcs
		return src if asset in ['video', 'fallback', 'poster'] or typeof src == 'string'

		# If we don't know the size yet, ask for size and return a poster in the
		# meantime
		if vm.containerWidth == null and vm.containerHeight == null
			vm.watchSize = true
			return img src, posterSize, posterSize

		# We should know the dimensions at this point, so calculate the size
		w = calcSize vm, 'width'
		h = calcSize vm, 'height'

		# Determine the format
		format = if supportsWebp then 'webp' else undefined

		# Make the image
		return img src, w, h,
			format: format
			quality: 95

# Make a dimension given the vm
calcSize = (vm, side) ->

	# If not a background image and no size provided, return null
	return if vm.background not in ['cover', 'contain'] and not vm[side]

	# Settings
	interval = 200
	density = window?.devicePixelRatio || 1

	# Calculate the size, rounding it to the nearest interval
	ucSide = side.charAt(0).toUpperCase() + side.substr(1)
	containerSize = vm["container#{ucSide}"]
	size = density * interval * Math.ceil containerSize / interval

	# Apply % from width or height
	if perc = vm[side]?.match(/(^[\d\.])+%$/)?[1]
		size *= perc / 100

	# Return the final size
	return Math.round size
