###
Based on TweenMax.js in the GSAP package, this imports just what's needed and
tries to prevent treeshaking
###
import TweenLite from 'gsap/TweenLite'
import { Expo } from 'gsap/EasePack'
import CSSPlugin from 'gsap/CSSPlugin'
import ColorPropsPlugin from 'gsap/ColorPropsPlugin'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import MorphSVGPlugin from '~/vendor/MorphSVGPlugin'

# Prevent tree shaking
plugins = [
	CSSPlugin
	ColorPropsPlugin
	ScrollToPlugin
	MorphSVGPlugin
]

# Re-export things that need to get imported by name
export {
	TweenLite
	Expo
}

###
TweenMax.js contents, for reference
---
import TweenLite, { TweenPlugin, Ease, Power0, Power1, Power2, Power3, Power4, Linear } from "./TweenLite.js";
import TweenMaxBase from "./TweenMaxBase.js";
import CSSPlugin from "./CSSPlugin.js";
import AttrPlugin from "./AttrPlugin.js";
import RoundPropsPlugin from "./RoundPropsPlugin.js";
import DirectionalRotationPlugin from "./DirectionalRotationPlugin.js";
import TimelineLite from "./TimelineLite.js";
import TimelineMax from "./TimelineMax.js";
import BezierPlugin from "./BezierPlugin.js";
import { Back, Elastic, Bounce, RoughEase, SlowMo, SteppedEase, Circ, Expo, Sine, ExpoScaleEase } from "./EasePack.js";

//the following two lines are designed to prevent tree shaking of the classes that were historically included with TweenMax (otherwise, folks would have to reference CSSPlugin, for example, to ensure their CSS-related animations worked)
export var TweenMax = TweenMaxBase;
TweenMax._autoActivated = [TimelineLite, TimelineMax, CSSPlugin, AttrPlugin, BezierPlugin, RoundPropsPlugin, DirectionalRotationPlugin, Back, Elastic, Bounce, RoughEase, SlowMo, SteppedEase, Circ, Expo, Sine, ExpoScaleEase];

export { TweenMax as default };
export { TweenLite, TimelineLite, TimelineMax, CSSPlugin, AttrPlugin, BezierPlugin, DirectionalRotationPlugin, RoundPropsPlugin, TweenPlugin, Ease, Power0, Power1, Power2, Power3, Power4, Linear, Back, Elastic, Bounce, RoughEase, SlowMo, SteppedEase, Circ, Expo, Sine, ExpoScaleEase };
###
