



























import { VExpandTransition } from 'vuetify/lib'
import '~/vendor/vuetify/stylus/app.styl'
export default

	components: { VExpandTransition }

	props: options: Object

	data: -> open: false

	computed:

		# Are we on the mobile nav?
		isMobileNav: -> @$store.state.viewport.width < 768

		# Which transition to use for the menu
		menuTransition: ->
			if @isMobileNav
			then 'v-expand-transition'
			else 'transition'

