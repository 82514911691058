


















import layoutHeader from '~/components/layout/header'
import cursorReplacement from '~/components/layout/cursor-replacement'
import initialLoader from '~/components/layout/initial-loader'
import unsupportedBrowser from '~/components/layout/unsupported-browser'
import structuredData from '~/components/global/structured-data'
export default
	name: 'Layout'

	components: {
		layoutHeader
		cursorReplacement
		initialLoader
		unsupportedBrowser
		structuredData
	}

	data: ->
		ready: false
		replaceCursor: process.env.REPLACE_CURSOR

	mounted: ->

		# Boot up smoth scrolling
		@$scrollbar.init @$refs.main

		# Wait a tick and reveal the site
		@$wait 0, => @ready = true

