





























import NavMenu from './nav-menu'
export default

	components: {
		NavMenu
	}

	data: ->
		links:
			Solutions:
				updatesKey: 'solutions'
				options:
					DTC: 'https://solutions.bukwild.com'
					B2B: 'https://solutions.bukwild.com/b2b'
			Studio:
				route: '/studio'
				updatesKey: 'staff'
			Work:
				route: '/work'
				updatesKey: 'caseStudies'
			Contact:
				route: '/contact'
				updatesKey: 'careers'

	methods:

		# Update the active route only when the page swap happens
		isActive: (path) ->
			if path == '/'
			then @$store.state.route.path == path
			else !!@$store.state.route.path.match path

