import { render, staticRenderFns } from "./font-swap.vue?vue&type=template&id=998c5216&scoped=true&lang=pug&"
import script from "./font-swap.vue?vue&type=script&lang=coffee&"
export * from "./font-swap.vue?vue&type=script&lang=coffee&"
import style0 from "./font-swap.vue?vue&type=style&index=0&id=998c5216&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "998c5216",
  null
  
)

export default component.exports