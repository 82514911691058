###
Stores the incoming utm state
###

export state = ->
	content: ''
	term: ''
	campaign: ''
	source: ''
	medium: ''

export mutations =

	# Store a utm value
	set: (state, { key, val }) -> state[key] = val
