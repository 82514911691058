import Vue from 'vue'

import scrollResponder from '~/components/global/scroll-responder'
Vue.component 'scroll-responder', scrollResponder

import rightArrow from '~/components/global/right-arrow'
Vue.component 'right-arrow', rightArrow

import bukBug from '~/components/global/buk-bug'
Vue.component 'buk-bug', bukBug

import fontSwap from '~/components/global/font-swap'
Vue.component 'font-swap', fontSwap

import colorSwap from '~/components/global/color-swap'
Vue.component 'color-swap', colorSwap

import parallaxFrame from '~/components/global/parallax-frame'
Vue.component 'parallax-frame', parallaxFrame

import placeholder from '~/components/global/placeholder'
Vue.component 'placeholder', placeholder

import { component as transitionPlaceholder } from './transition-placeholder'
Vue.component 'transition-placeholder', transitionPlaceholder

import updatesBadge from '~/components/global/updates-badge'
Vue.component 'updates-badge', updatesBadge

import PillBtn from '~/components/global/buttons/pill-btn'
Vue.component 'pill-btn', PillBtn
