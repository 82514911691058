












export default
	name: 'BlankLayout'
