


















import horizontalNav from './horizontal-nav'
import onScroll from '~/utils/on-scroll-mixin'
import 'vue-hamburger/index.css'
import hamburger from 'vue-hamburger'
export default

	mixins: [ onScroll ]

	components: { horizontalNav, hamburger }

	data: ->
		scrolling: null
		lastScrollY: 0
		open: false # Mobile open state
		show: true # Whether we show the header

	mounted: ->

		# Add custom link cursors to links
		@$addCustomLinkCursors @$el

		# Close when loading of a new page open
		@$root.$on 'loading:started', => @open = false

	computed:

		# Whether to show the header
		shouldShow: ->
			(@scrolling == 'up' or @scrollY < 10) and not @hideBecausePath

		# Routes that trigger hiding
		hideBecausePath: -> !!@path.match /^\/team\//

		# Vuex shorthand
		transitioning: -> @$store.state.route.transitioning
		path: -> @$store.state.route.path

	watch:

		# Fire global event based on when shown
		show: -> @$root.$emit "header:#{if @show then 'show' else 'hide'}"

		# Toggle showing the header, unless we're mid transition, in which case
		# don't change it so we don't see it thrashing.
		shouldShow:
			immediate: true
			handler: -> @show = @shouldShow unless @transitioning

		# When done transitioning, update the show state again
		transitioning: -> @show = @shouldShow if not @transitioning

		# Hide mid transition if going to a team profile
		hideBecausePath: -> @show = false if @hideBecausePath

		# Toggle scrolling of body on and off
		open: ->
			if @open then @$scrollbar.disable @$refs.nav.$el
			else @$scrollbar.enable()

	methods:

		# Determine direction of scroll
		onScroll: ->
			@scrolling = if @scrollY - @lastScrollY > 0 then 'down' else 'up'
			@lastScrollY = @scrollY

