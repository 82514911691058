###
A Vue Mixin that calls onResize when the window is resized
###
export default

	# Trigger callback on mount.  This acts like the watch had an "immediate:true"
	# but shouldn't fire before a component is ready to deal with it
	mounted: -> @onScroll()

	# Convenience accessor
	computed: scrollY: -> @$scroll?.y || 0

	# Listen to width changes
	watch: scrollY: -> @onScroll()

	# No-op that should be added to component this is mixed into
	methods: onScroll: ->
