import Vue from 'vue'
import * as Sentry from '@sentry/browser'

export default function (ctx, inject) {
  const opts = Object.assign({}, {"dsn":"https:\u002F\u002F3dce9a52c9774511b8c74d71abefa738@sentry.io\u002F1450763","environment":"production","release":"cf6bea0c7189000e42328dd917f55f0865d7768c","extra":{"url":"https:\u002F\u002Fwww.bukwild.com","deploy_url":"https:\u002F\u002F632c8b1554e2a40008d12368--bukwild.netlify.app"}}, {
    integrations: [
      new Sentry.Integrations.Dedupe,
      new Sentry.Integrations.ExtraErrorData,
      new Sentry.Integrations.InboundFilters,
      new Sentry.Integrations.FunctionToString,
      new Sentry.Integrations.TryCatch,
      new Sentry.Integrations.Breadcrumbs,
      new Sentry.Integrations.GlobalHandlers,
      new Sentry.Integrations.LinkedErrors,
      new Sentry.Integrations.UserAgent,
      new Sentry.Integrations.Vue({ Vue })
    ]
  })

  if (!opts.disabled) {
    Sentry.init(opts)
  }

  // Inject Sentry to the context as $sentry
  ctx.$sentry = Sentry
  inject('sentry', Sentry)
}
