

















import { TweenLite, Expo } from '~/plugins/gsap'
import trackMounted from '~/utils/track-mounted-mixin'

# Enter helper
enter = (el, from, to) ->
	return unless el
	TweenLite.fromTo el, 0.5, x: from,
		ease: Expo.easeOut
		x: to

		# This fixes an issue in the carousel with the active client, shown in
		# Freight, showing a bit of the red color.  It would be logical to reset
		# the display non when the leave animation is done, but not experiencing
		# any issues after this change (and the display:none in CSS), so leaving
		# alone for now.
		display: 'block'

# Leave helper
leave = (el, from, to, delay) ->
	return unless el
	TweenLite.fromTo el, 0.6, x: from,
		ease: Expo.easeInOut
		delay: delay
		x: to

# Module def
export default

	mixins: [ trackMounted ]

	props:
		active: Boolean
		controlled: Boolean

	data: -> over: false

	methods:

		# Don't trigger if active or touch
		onEnter: ->
			return if @controlled or @active or Modernizr.touchevents
			@over = true
			@playEnter()

		# Animate in from the right
		playEnter: ->

			# If already leaving, reverse the direction
			if @leaveTweens?[0]?.isActive()
				tween.reverse() for tween in @leaveTweens

			# Else, animate in from the right side
			else
				@enterTween = enter @$refs.def, '0%', '-100%'
				enter @$refs.defSlot, '0%', '100%'
				enter @$refs.alt, '100%', '0%'
				enter @$refs.altSlot, '-100%', '0%'

		# Don't trigger if active
		onLeave: ->
			return if @controlled or (@active and not @over) or Modernizr.touchevents
			@over = false
			@playLeave()

		# Animate out to the left and wait for the previous animation to end
		playLeave: ->

			# Delay but the amount left on the enter tween so
			# enter does finish
			delay = unless @enterTween then 0
			else (1 - @enterTween.progress()) * @enterTween.duration()

			# Run tweens
			@leaveTweens = [
				leave @$refs.def, '100%', '0%', delay
				leave @$refs.defSlot, '-100%', '0%', delay
				leave @$refs.alt, '0%', '-100%', delay
				leave @$refs.altSlot, '0%', '100%', delay
			]

