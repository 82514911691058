


















import onResize from '~/utils/on-resize-mixin'
import { TweenLite, Expo } from '~/plugins/gsap'
import svgpath from 'svgpath'

# Color hexes
red = '#f9352b'
black = '#000000'
blue = '#015caf'

# Export the plugin
export default

	mixins: [ onResize ]

	data: ->
		enabled: false
		down: false
		hide: false

		# Icon dimensions
		radius: 10

		# Cursor tween settings
		icon: 'circle'
		iconPath: null
		scale: 1
		fillAlpha: 1
		dashGap: 0
		rotate: 0
		color: black

		# Mouse position
		easing: 0.5
		now:
			x: null
			y: null
		last:
			x: null
			y: null

	# Only enable on non-touch devices
	mounted: ->
		if Modernizr.svgpath2d and not Modernizr.touchevents
			@enabled = true
			@$nextTick @enable

	destroyed: -> @disable()

	computed:

		# Which icon SVG to show
		iconSvg: -> switch @icon
			when 'horizontal' then 'triangle'
			else 'circle'

		# Combine the icon and the down state into a single trigger that can be
		# watched
		iconState: -> "#{@icon}:#{ if @down then 'down' else 'up'}"

		# Compute the circle path data given the data settings
		circlePath: ->
			r = @radius
			"M 0, #{r/2} a #{r/2},#{r/2} 0 1,1 #{r},0 a #{r/2},#{r/2} 0 1,1 -#{r},0"

		# Compute the triangle path
		trianglePath: ->
			r = @radius
			m = 0.9 # How much shorter than circle it should be
			h = r * m
			"M 0 #{h} L #{r/2} #{r - h} L #{r} #{h} z"

	watch:

		# Transition to a new icon style
		iconSvg: ->
			TweenLite.to @$refs.icon, 0.5,
				ease: Expo.easeOut
				morphSVG: '#'+@iconSvg
				onUpdate: @captureIcon

		# Transition between icon states
		iconState: (val) ->

			# Reset to these if not defined
			defaults =
				ease: Expo.easeOut
				scale: 1
				fillAlpha: 1
				dashGap: 0
				color: black

			switch val
				when 'circle:up'
					TweenLite.to @, 0.5, defaults
				when 'circle:down'
					TweenLite.to @, 0.5, {
						...defaults
						scale: 2
					}
				when 'link:up'
					TweenLite.to @, 0.5, {
						...defaults
						fillAlpha: 0
						scale: 4
						color: red
					}
				when 'link:down'
					TweenLite.to @, 0.5, {
						...defaults
						fillAlpha: 0
						scale: 3
						color: red
					}
				when 'loading:up'
					TweenLite.to @, 0.5, {
						...defaults
						fillAlpha: 0
						scale: 4
						dashGap: 10
						color: blue
					}
				when 'loading:down'
					TweenLite.to @, 0.5, {
						...defaults
						fillAlpha: 0
						scale: 3
						dashGap: 10
						color: blue
					}
				when 'horizontal:up'
					TweenLite.to @, 0.5, {
						...defaults
						scale: 3
					}
				when 'horizontal:down'
					TweenLite.to @, 0.5, {
						...defaults
						scale: 2
					}

	methods:

		# Enable cursor replacement
		enable: ->

			# Get canvas
			@canvas = @$refs.canvas
			@ctx = @canvas.getContext '2d'

			# Make the icon path
			@captureIcon()

			# Re-trigger resize function since this happens after the initial resiz
			@onResize()

			# Listen for mouse events
			window.addEventListener 'mousemove', @onMove
			window.addEventListener 'mousedown', @onPress
			window.addEventListener 'mouseup', @onRelease
			document.addEventListener 'mouseover', @onWindowEnter
			document.addEventListener 'mouseout', @onWindowLeave

			# Listen for curosr style events
			@$root.$on 'cursor:show', @onCursorOver
			@$root.$on 'cursor:hide', @onCursorOff

			# Do the initial draw
			@rafId = requestAnimationFrame @draw

			# Add a body class indicating it's added
			document.body.classList.add 'cursor-replaced'

		# Disable cursor replacement
		disable: ->
			cancelAnimationFrame @rafId if @rafId
			window.removeEventListener 'mousemove', @onMove
			window.removeEventListener 'mousedown', @onPress
			window.removeEventListener 'mouseup', @onRelease
			document.removeEventListener 'mouseenter', @onWindowEnter
			document.removeEventListener 'mouseleave', @onWindowLeave
			@$root.$off 'cursor:show', @onCursorOver
			@$root.$off 'cursor:hide', @onCursorOff
			document.body.classList.remove 'cursor-replaced'

		# Draw the cursor
		draw: ->
			@clear()
			@easeMousePosition()
			@drawCursor()
			@rafId = requestAnimationFrame @draw

		# Clear the stage
		clear: -> @ctx.clearRect 0, 0, @viewportW, @viewportH

		# Apply easing to the mouse position
		easeMousePosition: ->
			return unless @now.x && @now.y
			@$cursor.x += (@now.x - @$cursor.x) * @easing
			@$cursor.y += (@now.y - @$cursor.y) * @easing

		# Draw the cursor
		drawCursor: ->
			return unless @iconPath and @$cursor.x and @$cursor.y

			# Set the mouse position
			x = Math.round @$cursor.x - (@radius * @scale)/2
			y = Math.round @$cursor.y - (@radius * @scale)/2

			# Set the fill color
			@ctx.fillStyle = "rgba(0,0,0,#{@fillAlpha})"

			# Apply rotation to icon if loading
			rotation = if @icon == 'loading' then @rotate += 5 else 0

			# Transform the icon path
			path = svgpath @iconPath
			.rotate rotation, @radius / 2, @radius / 2
			.scale @scale
			.translate x, y
			.toString()

			# Draw a stroke around the shake
			@ctx.lineWidth = 2
			@ctx.setLineDash [5, @dashGap]
			@ctx.strokeStyle = @color
			@ctx.stroke new Path2D path

			# Render the icon
			@ctx.fill new Path2D path

		# Save the current icon path
		captureIcon: -> @iconPath = @$refs.icon.getAttribute 'd'

		# Handle mouse move
		onMove: (e) ->
			@now.x = e.clientX
			@now.y = e.clientY

		# Size the canvas
		onResize: ->
			return unless @canvas
			@canvas.width = @viewportW
			@canvas.height = @viewportH

		# Switch cursor styles
		onCursorOver: (icon) ->
			return if @icon == 'loading' # Wait for loading to finish
			@icon = icon

		# Remove a cursor style
		onCursorOff: (icon) ->
			return if @icon == 'loading' and icon != 'loading'
			@icon = 'circle'

		# On mouse down
		onPress: -> @down = true

		# On mouse up
		onRelease: -> @down = false

		# Hide the cursor when mouse leaves the browser and then restore it
		onWindowLeave: -> @hide = true
		onWindowEnter: (e) ->
			@hide = false
			@$cursor.x = e.clientX
			@$cursor.y = e.clientY

