// Deps
const Vue = require('vue')
const serialize = require('serialize-javascript')
const utils = require('bukwild-contentful-utils/index.js')

// Extract serialized options
options = eval({"config":{"space":"1ar6lmew6vkn","access_token":"diqz7qleqa1bFIGid5qPDrhIRZihJa2a8DejlUJQ6l4","host":"cdn.contentful.com"}})

// Configure the API
utils.config(options.config)

// Inject the utils into the app
module.exports = function(ctx, inject) {
	inject('contentful', utils)
}
