import { render, staticRenderFns } from "./cursor-replacement.vue?vue&type=template&id=0860124b&scoped=true&lang=pug&"
import script from "./cursor-replacement.vue?vue&type=script&lang=coffee&"
export * from "./cursor-replacement.vue?vue&type=script&lang=coffee&"
import style0 from "./cursor-replacement.vue?vue&type=style&index=0&id=0860124b&lang=stylus&scoped=true&"
import style1 from "./cursor-replacement.vue?vue&type=style&index=1&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0860124b",
  null
  
)

export default component.exports