












import { isInternal } from 'vue-routing-anchor-parser'
export default

	props: url: String

	computed:

		# Is the URL an external URL
		isExternal: -> !isInternal @url if @url

