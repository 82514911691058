###
Track when the user has last seen a section
###

# Deps
{ client } = require 'bukwild-contentful-utils'

# Get initial seen at value
getSeenAt = (key, defaultDate = null) ->
	(localStorage?.getItem "seen-#{key}") ||
		defaultDate?.getTime() ||
		Date.now() - 31 * 24 * 60 * 60 * 1000 # 31 days ago

export state = ->

	# The studio staff listing
	staff:
		label: 'People'
		entryId: '5mPT9QqAAu2fletESrwtw'
		updatedAt: null
		seenAt: null
		seenWhenRoute: /^\/studio/

	# The case studies listing on the work tower
	caseStudies:
		label: 'Case Studies'
		entryId: '6Fnj3DRoK5I1sARSt4QP0h'
		updatedAt: null
		seenAt: null
		seenWhenRoute: /^\/work/

	# The copy block listing careers
	careers:
		label: 'Careers'
		entryId: '7tESo5FyHk9xsq0SagQgsx'
		updatedAt: null
		seenAt: null
		seenWhenRoute: /^\/contact/

export mutations =

	# Set updated at
	setUpdatedAt: (state, { key, updatedAt }) ->
		state[key].updatedAt = updatedAt

	# Set seenAt on client load
	setInitialSeenAt: (state) ->
		state.staff.seenAt = getSeenAt 'staff'
		state.caseStudies.seenAt = getSeenAt 'caseStudies'
		# Before careers was launched
		state.careers.seenAt = getSeenAt 'careers', new Date 2019, 9, 25

	# Update seenAt when route changes
	onRoute: (state, route) ->
		for key, { seenWhenRoute } of state
			if route.path.match seenWhenRoute
				state[key].seenAt = now = Date.now()
				localStorage.setItem "seen-#{key}", now

export getters =

	# Get the section alues
	sections: (state) -> Object.values state

	# Get the entry ids
	entryIds: (state, getters) -> getters.sections.map ({ entryId }) -> entryId

export actions =

	# Get last updated date for different sections
	fetch: ({ commit, getters, state })->

		# Get the watched entries
		{ items: entries } = await client.getEntries
			'sys.id[in]': getters.entryIds.join(',')
			include: 0

		# Get a hash of watched keys and their last updated date.  This tests to
		# make sure the entry is found, like the ids are still valid.
		for key, { entryId } of state
			if entry = entries.find (entry) -> entry.sys.id == entryId
				updatedAt = (new Date(entry.sys.updatedAt)).getTime()
				commit 'setUpdatedAt', { key, updatedAt }
