












export default

	props: aspect: Number

	computed:

		styles: -> '--aspect-ratio': "#{100 / @aspect}%" if @aspect

