












export default

	data: -> loading: false

	methods:

		# Start loading
		start: ->
			@loading = true
			@$root.$emit 'loading:started'

		# Stop loading once the animation at least eaches it's middle point
		finish: ->

			# Used internally to trigger transition
			@loading = false

			# Used in the nuxt config transition to know when to switch pages
			@$root.$emit 'loading:finished'

		# Treat fails the same way
		fail: -> @finish()

