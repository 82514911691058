# Deps
import Vue from 'vue'
import Smooth from 'smooth-scrolling'
import { TweenLite } from '~/plugins/gsap'
import throttle from 'lodash/throttle'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

# Customize Smooth scrolling to add a class to the scrollbar when page height
# is less than viewport height
class SmoothCustom extends Smooth

	# Keep the original behavior that gets turned off when you extend
	constructor: (opt) ->
		super opt
		@extends = false

	# Toggle display of scrollbar depending on whether content is > viewport
	resize: ->
		super.resize()
		if @vars.bounding <= 0 then @hide() else @show()

	# Hide/show the scrollbar
	hide: -> @dom.scrollbar.el.classList.add 'hide'
	show: -> @dom.scrollbar.el.classList.remove 'hide'

	# Automatically hide and show when on/off called
	on: ->
		super.on()
		@show()
	off: ->
		super.off()
		@hide()

# Make the scrollY position a global obverserable.  I tried using VueX
# but it super bogged down the dev tools ... too many mutations.
# https://mobile.twitter.com/JakeDohm/status/1111285005639213056/photo/1
export scroll = Vue.observable y: 0

# Make scrollbar instance with public methods for scrolling
export scrollbar = new class Scrollbar

	# Init smooth scrolling
	init: (el) ->

		# Hijack the scrolling to smooth if not a touch device
		if !process.env.DISABLE_SMOOTH_SCROLL && @hijacked = !Modernizr.touchevents
			@smooth = new SmoothCustom
				section: el
				vs: useKeyboard: false
				callback: (scrollY) => @onScroll scrollY
			@smooth.init()

			# Do an initial fire so callbacks can handle the inital position
			@onScroll @smooth.vars.current

		# Else, listen to normal scroll events
		else
			window.addEventListener 'scroll', => @onScroll window.scrollY
			@onScroll window.scrollY

	# Update subscribers on scroll
	onScroll: (scrollY) ->  scroll.y = scrollY

	# Scroll to a position.  If not hijacking, wait to resolve promise until
	# the tween is done.  The hijacked version can smoothly handle it
	scrollTo: (offset) -> new Promise (done) =>
			if @hijacked
				@smooth.scrollTo offset
				done()
			else
				TweenLite.to window, .5,
					scrollTo:
						y: offset
						autoKill: false
					onComplete: done

	# Scroll to an element
	scrollToEl: (el) ->
		elOffset = el.getBoundingClientRect().top + scroll.y
		headerH = 65 # Rough estimate
		@scrollTo elOffset - headerH

	# Immediately jump to a position
	jumpTo: (offset) ->
		if @hijacked
			@smooth.vars.current = @smooth.vars.last = @smooth.vars.target = offset
			@onScroll offset
		else window.scrollTo x, offset

	# Tell smooth scroll to recalculate after a layout change
	recalculate: throttle ->
		@smooth?.resize() if @hijacked
	, 100

	# Disable scrolling, like when mobile nav is open.  You must suppy an element
	# where scrolling is allowed for this to work when non-hijacked on iOS (this
	# is a requirement of the library we're using)
	disable: (allowedEl) ->
		if @hijacked
		then @smooth.off()
		else disableBodyScroll allowedEl, reserveScrollBarGap: true

	# Re-enable body scroll
	enable: ->
		if @hijacked
		then @smooth.on()
		else clearAllBodyScrollLocks()

	# Disable/enable keyboard scrolling
	disableKeyboard: ->
		if @smooth?.vs
			document.removeEventListener 'keydown', @smooth.vs._onKeyDown
	enableKeyboard: ->
		if @smooth?.vs
			document.addEventListener 'keydown', @smooth.vs._onKeyDown

# Inject globals
export default ({ }, inject) ->
	inject 'scroll', scroll
	inject 'scrollbar', scrollbar
