












import organization from './organization'
import jobPosting from './job-posting'
export default

	props:
		type: String
		payload: Object|String

	computed:

		# Convert to string
		jsonLdString: -> JSON.stringify @jsonLd

		# Call formatters on the payload
		jsonLd: ->
			formatter = switch @type
				when 'Organization' then organization
				when 'JobPosting' then jobPosting
			formatter @payload

