import Vue from 'vue'
import Meta from 'vue-meta'
import { createRouter } from './router.js'
import NoSsr from './components/no-ssr.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from './components/nuxt-error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_vuetify_0cb36fee from 'nuxt_plugin_vuetify_0cb36fee' // Source: ./vuetify.js (mode: 'all')
import nuxt_plugin_swplugin_291ca2df from 'nuxt_plugin_swplugin_291ca2df' // Source: ./sw.plugin.js (mode: 'client')
import nuxt_plugin_nuxticons_0db8167c from 'nuxt_plugin_nuxticons_0db8167c' // Source: ./nuxt-icons.js (mode: 'all')
import nuxt_plugin_googletagmanager_4db08eec from 'nuxt_plugin_googletagmanager_4db08eec' // Source: ./google-tag-manager.js (mode: 'client')
import nuxt_plugin_nuxtplugin43164e1c_def9e34a from 'nuxt_plugin_nuxtplugin43164e1c_def9e34a' // Source: ./nuxt.plugin.43164e1c.js (mode: 'all')
import nuxt_plugin_nuxtplugin5b74c196_06a0ee0d from 'nuxt_plugin_nuxtplugin5b74c196_06a0ee0d' // Source: ./nuxt.plugin.5b74c196.js (mode: 'all')
import nuxt_plugin_nuxtplugin05378361_34638963 from 'nuxt_plugin_nuxtplugin05378361_34638963' // Source: ./nuxt.plugin.05378361.js (mode: 'all')
import nuxt_plugin_nodelistforeach_0dbbd74d from 'nuxt_plugin_nodelistforeach_0dbbd74d' // Source: ./nuxt-polyfill/opt/build/repo/vendor/polyfills/nodelist-foreach.js (mode: 'all')
import nuxt_plugin_intersectionobserver_0db7f058 from 'nuxt_plugin_intersectionobserver_0db7f058' // Source: ./nuxt-polyfill/intersection-observer.js (mode: 'all')
import nuxt_plugin_sentryclient_0ecf1882 from 'nuxt_plugin_sentryclient_0ecf1882' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_globalcomponents_67f95f9b from 'nuxt_plugin_globalcomponents_67f95f9b' // Source: ../plugins/global-components (mode: 'all')
import nuxt_plugin_transitionplaceholder_1e8d7dc0 from 'nuxt_plugin_transitionplaceholder_1e8d7dc0' // Source: ../plugins/transition-placeholder (mode: 'all')
import nuxt_plugin_backgroundcolor_b4f0f2ee from 'nuxt_plugin_backgroundcolor_b4f0f2ee' // Source: ../plugins/background-color (mode: 'all')
import nuxt_plugin_vuevisual_293a95ac from 'nuxt_plugin_vuevisual_293a95ac' // Source: ../plugins/vue-visual (mode: 'all')
import nuxt_plugin_windoweventmediator_38b9eade from 'nuxt_plugin_windoweventmediator_38b9eade' // Source: ../plugins/window-event-mediator (mode: 'client')
import nuxt_plugin_storeviewportdimensions_4bf71983 from 'nuxt_plugin_storeviewportdimensions_4bf71983' // Source: ../plugins/store-viewport-dimensions (mode: 'client')
import nuxt_plugin_storeinitialroute_2d6a5822 from 'nuxt_plugin_storeinitialroute_2d6a5822' // Source: ../plugins/store-initial-route (mode: 'all')
import nuxt_plugin_smoothscrolling_7597c11b from 'nuxt_plugin_smoothscrolling_7597c11b' // Source: ../plugins/smooth-scrolling (mode: 'client')
import nuxt_plugin_masonry_55af5c20 from 'nuxt_plugin_masonry_55af5c20' // Source: ../plugins/masonry (mode: 'client')
import nuxt_plugin_impetus_d33c4998 from 'nuxt_plugin_impetus_d33c4998' // Source: ../plugins/impetus (mode: 'client')
import nuxt_plugin_marked_7e9ae21e from 'nuxt_plugin_marked_7e9ae21e' // Source: ../plugins/marked (mode: 'all')
import nuxt_plugin_customcursor_06ceaecd from 'nuxt_plugin_customcursor_06ceaecd' // Source: ../plugins/custom-cursor (mode: 'client')
import nuxt_plugin_index_0cfea5ac from 'nuxt_plugin_index_0cfea5ac' // Source: ../utils/index (mode: 'all')
import nuxt_plugin_captureutm_395431fc from 'nuxt_plugin_captureutm_395431fc' // Source: ../plugins/capture-utm (mode: 'client')
import nuxt_plugin_gtmpagemeta_2cbeefb1 from 'nuxt_plugin_gtmpagemeta_2cbeefb1' // Source: ../plugins/gtm-page-meta (mode: 'client')
import nuxt_plugin_updateseensection_357fa51c from 'nuxt_plugin_updateseensection_357fa51c' // Source: ../plugins/update-seen-section (mode: 'client')

// Component: <NoSsr>
Vue.component(NoSsr.name, NoSsr)

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>`
Vue.component(Nuxt.name, Nuxt)

// vue-meta configuration
Vue.use(Meta, {
  keyName: 'head', // the component option name that vue-meta looks for meta info on.
  attribute: 'data-n-head', // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: 'data-n-head-ssr', // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: 'hid' // the property name that vue-meta uses to determine whether to overwrite or append a tag
})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  const registerModule = store.registerModule
  store.registerModule = (path, rawModule, options) => registerModule.call(store, path, rawModule, Object.assign({ preserveState: process.client }, options))

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    router,
    store,
    nuxt: {
      defaultTransition,
      transitions: [ defaultTransition ],
      setTransitions(transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [ transitions ]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },
      err: null,
      dateErr: null,
      error(err) {
        err = err || null
        app.context._errored = !!err
        err = err ? normalizeError(err) : null
        const nuxt = this.nuxt || this.$options.nuxt
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) ssrContext.nuxt.error = err
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    store,
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  const inject = function (key, value) {
    if (!key) throw new Error('inject(key, value) has no key provided')
    if (typeof value === 'undefined') throw new Error('inject(key, value) has no value provided')
    key = '$' + key
    // Add into app
    app[key] = value

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) return
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Vue.prototype.hasOwnProperty(key)) {
        Object.defineProperty(Vue.prototype, key, {
          get() {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Plugin execution

  if (typeof nuxt_plugin_vuetify_0cb36fee === 'function') {
    await nuxt_plugin_vuetify_0cb36fee(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_swplugin_291ca2df === 'function') {
    await nuxt_plugin_swplugin_291ca2df(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxticons_0db8167c === 'function') {
    await nuxt_plugin_nuxticons_0db8167c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googletagmanager_4db08eec === 'function') {
    await nuxt_plugin_googletagmanager_4db08eec(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtplugin43164e1c_def9e34a === 'function') {
    await nuxt_plugin_nuxtplugin43164e1c_def9e34a(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtplugin5b74c196_06a0ee0d === 'function') {
    await nuxt_plugin_nuxtplugin5b74c196_06a0ee0d(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtplugin05378361_34638963 === 'function') {
    await nuxt_plugin_nuxtplugin05378361_34638963(app.context, inject)
  }

  if (typeof nuxt_plugin_nodelistforeach_0dbbd74d === 'function') {
    await nuxt_plugin_nodelistforeach_0dbbd74d(app.context, inject)
  }

  if (typeof nuxt_plugin_intersectionobserver_0db7f058 === 'function') {
    await nuxt_plugin_intersectionobserver_0db7f058(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_0ecf1882 === 'function') {
    await nuxt_plugin_sentryclient_0ecf1882(app.context, inject)
  }

  if (typeof nuxt_plugin_globalcomponents_67f95f9b === 'function') {
    await nuxt_plugin_globalcomponents_67f95f9b(app.context, inject)
  }

  if (typeof nuxt_plugin_transitionplaceholder_1e8d7dc0 === 'function') {
    await nuxt_plugin_transitionplaceholder_1e8d7dc0(app.context, inject)
  }

  if (typeof nuxt_plugin_backgroundcolor_b4f0f2ee === 'function') {
    await nuxt_plugin_backgroundcolor_b4f0f2ee(app.context, inject)
  }

  if (typeof nuxt_plugin_vuevisual_293a95ac === 'function') {
    await nuxt_plugin_vuevisual_293a95ac(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_windoweventmediator_38b9eade === 'function') {
    await nuxt_plugin_windoweventmediator_38b9eade(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_storeviewportdimensions_4bf71983 === 'function') {
    await nuxt_plugin_storeviewportdimensions_4bf71983(app.context, inject)
  }

  if (typeof nuxt_plugin_storeinitialroute_2d6a5822 === 'function') {
    await nuxt_plugin_storeinitialroute_2d6a5822(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_smoothscrolling_7597c11b === 'function') {
    await nuxt_plugin_smoothscrolling_7597c11b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_masonry_55af5c20 === 'function') {
    await nuxt_plugin_masonry_55af5c20(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_impetus_d33c4998 === 'function') {
    await nuxt_plugin_impetus_d33c4998(app.context, inject)
  }

  if (typeof nuxt_plugin_marked_7e9ae21e === 'function') {
    await nuxt_plugin_marked_7e9ae21e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_customcursor_06ceaecd === 'function') {
    await nuxt_plugin_customcursor_06ceaecd(app.context, inject)
  }

  if (typeof nuxt_plugin_index_0cfea5ac === 'function') {
    await nuxt_plugin_index_0cfea5ac(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_captureutm_395431fc === 'function') {
    await nuxt_plugin_captureutm_395431fc(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtmpagemeta_2cbeefb1 === 'function') {
    await nuxt_plugin_gtmpagemeta_2cbeefb1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_updateseensection_357fa51c === 'function') {
    await nuxt_plugin_updateseensection_357fa51c(app.context, inject)
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, () => {
        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from, next) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    app,
    store,
    router
  }
}

export { createApp, NuxtError }
