###
Make a directive for adding custom cursors on hover.  I'm trusting to Vue to
not have memory links and not cleaning up any of these listeners myself 😬
https://github.com/vuejs/vue/issues/314#issuecomment-417553271
###
import Vue from 'vue'
export default ({}, inject) ->

	# Get a reference to the app to share
	app = null
	window.onNuxtReady (_app) -> app = _app

	# Make a helper to add that adds a link style custom cursor to all (a)s in
	# the elements children, including itself
	inject 'addCustomLinkCursors', (el, type = 'link') ->
		return if !el or el.nodeName == '#comment'
		addListeners el, type if el.tagName == 'A'
		el.querySelectorAll 'a'
		.forEach (el) -> addListeners el, type

	# Install the directive version which defaults to a "link"
	# Usage: `v-custom-cursor:link`
	Vue.directive 'custom-cursor', bind: (el, { arg = 'link' }) ->
		addListeners el, arg

	# Make a bunch of named event handlers so I can re-use the same handler to
	# conserve memory and also prevent duplicate event registration
	# https://stackoverflow.com/a/47337711
	handlers = {}
	['link', 'horizontal'].forEach (type) ->
		['show', 'hide'].forEach (state) ->
			handlers["#{type}:#{state}"] = ->
				app.$emit "cursor:#{state}", type

	# Dry up adding listeners
	addListeners = (el, type) ->
		el.addEventListener 'mouseenter', handlers["#{type}:show"]
		el.addEventListener 'mouseleave', handlers["#{type}:hide"]

	# Also, inject an observable to track the position throughout the site
	inject 'cursor', Vue.observable x: null, y: null
