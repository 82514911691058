/**
 * Nuxt loses the current directory at this point, so I had to refer to the
 * the directive src file through the module name.
 *
 * Also, I can't passthrough objects directly from module.js to here so I'm
 * JSON-ing it and then parsing it back out so I can send arrays through to
 * the directive.
 */
import Vue from 'vue'
import directive from 'vue-routing-anchor-parser/index.js'
directive.settings(JSON.parse('{"addBlankToExternal":true,"internalHosts":["localhost:3000","www.bukwild.com","staging.bukwild.com","bukwild.netlify.com","bukwild-stage.netlify.com"]}'));
Vue.directive('parse-anchors', directive)
