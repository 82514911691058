###
Don't render slotted component until transitions are finished.  And then
render staggered.
###

# Deps
import Vue from 'vue'
import { wait } from '~/utils'

# Will contain placeholders that are waiting to be revealed
placeholders = []

# Export a plugin that coordinates multiple placeholders
export default ({ }, inject) ->

	# Inject registration function
	inject 'registerTransitionPlaceholder', (vm) -> placeholders.push vm

	# Show all placeholders whenever a transition is finished
	app = null
	window?.onNuxtReady (_app) ->
		app = _app
		app.$watch '$store.state.route.transitioning',
			immediate: true
			handler: (transitioning) ->
				if not transitioning
					requestAnimationFrame enablePlaceholders

	# Enable all of the vms in the placeholders array
	enablePlaceholders = ->

		# Show the next placeholder
		if placeholders.length
			vm = placeholders.shift()
			vm.show = true

		# Run again if there are still placeholders left
		if placeholders.length
			requestAnimationFrame enablePlaceholders

		# Otherwise, recalculate the scroll position
		else wait 0, -> app.$scrollbar.recalculate()

# Export the rendering function component that does the swap when ready
export component =

	props: aspect: Number

	# Don't show the slotted content if a transition is happening when the
	# placeholder is first crated
	data: -> show: !@$store.state.route.transitioning

	# Register this instance with the plugin if initially hide
	created: -> @$registerTransitionPlaceholder @ unless @show

	# Show div first then render the slot when ready to show.
	render: (create) ->

		# Show the slotted content, adding cursors to it after it has a chance to
		# mount.
		if @show
			if @$addCustomLinkCursors # Won't exist during SSR
				@$wait 0, => @$addCustomLinkCursors @$el
			return @$slots.default

		# Show the placeholder box
		else create Vue.component('placeholder'), props: aspect: @aspect
