###
Store viewport values
###

# The store
export state = ->
	width: null
	height: null

export mutations =

	# Store the viewport dimensions
	setDimensions: (state, { width, height }) ->
		state.width = width
		state.height = height
