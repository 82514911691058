# Deps
import Vue from 'vue'

# Invert set timeout
export wait = (ms, func) -> setTimeout func, ms

# Make a CSS class fro the string
export cssClass = (str, prefix = null) ->
	return unless str
	str = str.replace(' ', '-').toLowerCase()
	if prefix then str = prefix + '-' + str
	return str

# Helper used within blocks for getting additional data of references who
# don't initially have it because of restricting the amount of included
# relations.  And we're reducing the included relations MAINLY because the
# inclusion was causing a major framerate hitch when moving to pages with
# grid blocks.  Which I expect was because of circular JSON references but this
# feels cleaner anyway ... it's more network requests but less transfer...
export loadRefs = (contentType, refName, include = 1, select = []) ->

	# Return a function that accepts the arguments of asyncData
	({ app }, block) ->

		# Get the ordered ids of the items
		ids = (block[refName] || []).map (entry) -> entry?.sys?.id

		# Get the items
		{ items } = await app.$contentful.getEntries contentType,
			'sys.id[in]': ids.join ','
			include: include
			select: select.join ','

		# Order the items in the same order as the ids
		items.sort (a, b) -> ids.indexOf(a.sys.id) - ids.indexOf(b.sys.id)

		# Store the items on the block
		Vue.set block, refName, items

# Helper for making meta fields
export metaTag = (key, val, keyAttribute = null) ->
	if !@keyAttribute
		keyAttribute = if key.match /^og:/ then 'property' else 'name'
	hid: key
	"#{keyAttribute}": key
	content: val


# Also inject into global scope
export default ({}, inject) ->
	inject 'wait', wait
	inject 'cssClass', cssClass
	inject 'metaTag', metaTag
