















import trackMounted from '~/utils/track-mounted-mixin'
export default

	mixins: [ trackMounted ]

	props: active: Boolean

