###
Caputure UTM query params to vuex
###

# The UTM query params we check for
params = [
	'utm_content'
	'utm_term'
	'utm_campaign'
	'utm_source'
	'utm_medium'
]

# Store UTM query params to vuex
module.exports = ({ route, store }) ->
	for param in params
		if val = route.query[param]
			key = param.substr 4 # Strip "utm_"
			store.commit 'utm/set',
				key: key
				val: val
