












import onResize from '~/utils/on-resize-mixin'
import onScroll from '~/utils/on-scroll-mixin'
export default

	mixins: [ onResize, onScroll ]

	props:
		parallax: Number # Parallax multiplier
		parallaxEquation: Function # Customize how parallax is applied

	data: ->
		startsInViewport: null
		elY: null # The scroll top of the elemenet
		elH: null # The height of this element

	# Subscribe to updates
	mounted: ->
		@startsInViewport = @isInViewport()
		@$root.$on 'masonry:layoutComplete', @onResize

	# Cleanup
	destroyed: ->
		@$root.$off 'masonry:layoutComplete', @onResize

	computed:

		# Add parallax transform
		styles: ->
			if @parallaxOffset
				transform: "translate3d(0px, #{@parallaxOffset}px, 0px)"

		# Calculate the offset to apply to element for parallax
		parallaxOffset: ->
			offset = 0

			# If parallaxing, multiply by the scroll by a multiplier
			if @parallax or @parallaxEquation
				offset += @applyParallax @scrollY

				# Unless the component began life visible, add an offset that
				# compensates for how far down the page the component starts out.  This
				# is so that the parallax effect becomes a net 0 when it is in the
				# center of the page.  We're expliciting check if false so that this
				# doesn't get applied before we know if it started in viewport, like
				# when the value is null.
				offset += @parallaxAdjustment if @startsInViewport == false

			# Clamp the offset to it's max and min values
			if @maxOffset
				offset = Math.min @maxOffset, Math.max @maxOffset * -1, offset

			# Return final offset adjustment
			return offset

		# The amount to adjust the parallax effect so that it is relative to when
		# the element is centered in the viewport.  Also, as a seperate computed
		# property, this math doesn't have to get applied on every scrollY update.
		parallaxAdjustment: -> @applyParallax (@viewportH - @elH) / 2 - @elY

		# What the maximum absolute offset that is applied before the element is
		# fully offscreen.
		# https://math.stackexchange.com/a/3221618/672936
		maxOffset: ->
			return unless @parallax and not @parallaxEquation
			Math.abs @travelToHide * (@parallax / (1 - @parallax))

		# The maximum travel before to get the element offscreen without parallax
		# applied
		travelToHide: ->
			if @startsInViewport
			then @elH + @elY
			else @elH + (@viewportH - @elH) / 2

	methods:

		# Apply parallax effect using choice of equation
		applyParallax: (val) ->
			return 0 unless val
			if @parallaxEquation
			then @parallaxEquation val
			else val * @parallax

		# Is this currently in the viewport?
		isInViewport: ->
			@captureDimensions()
			@elY <= @viewportH

		# Store dimensions of element
		captureDimensions: ->
			{ top, height } = @$el.getBoundingClientRect()
			@elY = top + @scrollY - @parallaxOffset
			@elH = height

		# Capture element size and offset without parallax transform applied
		onResize: -> @captureDimensions()

